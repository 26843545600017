import { userStore } from '@context/userStore';
import Icon from '@elements/Icon';
import Modal from '@elements/Modal';
import Button from '@elements/buttons/Button';
import { classNames } from '@helpers/classNames';
import { isOrganisationAdmin, sendEnterpriseExtendSeatsRequest } from '@providers/enterprise';
import { getProfileData } from '@providers/profile';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LegendItem = ({ label, bgColor }) => {
  return (
    <div className="flex items-center gap-2">
      <div className={classNames('mt-0.5 h-2.5 w-2.5', bgColor)} />
      <div>{label}</div>
    </div>
  );
};

LegendItem.propTypes = {
  label: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
};

const ProgressBar = ({ fractionActive, fractionPending }) => {
  const widthActive = fractionActive >= 100 ? '100%' : `calc(${fractionActive}% - 1px)`;
  const widthPending = fractionPending >= 100 ? '100%' : `calc(${fractionPending}% - 1px)`;

  const activeColor = 'border-accent bg-accent';
  const pendingColor = 'bg-accent/60 border-accent/60 opacity-60';

  return (
    <div className="relative size-full">
      <div
        className={`absolute inset-y-0 z-20 rounded-full border ${activeColor}`}
        style={{ width: widthActive }}
      />
      <div
        className={`absolute inset-y-0 z-10 rounded-full border ${pendingColor}`}
        style={{ width: widthPending }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  fractionActive: PropTypes.number.isRequired,
  fractionPending: PropTypes.number.isRequired,
};

/**
 * SeatComponent is a component that displays seat information and a progress bar.
 * It takes 'used' and 'total' as props to calculate and display the seat usage.
 */
const Seats = ({ used, pending, total }) => {
  const seatsConsumed = used + pending;

  const fractionActive = Math.round((used / total) * 100);
  const fractionPending = Math.round((seatsConsumed / total) * 100);

  const [showModal, setShowModal] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailNotSent, setEmailNotSent] = useState(false);

  const { profileId } = userStore();

  const onClickModal = () => {
    setShowModal(!showModal);
  };

  function onClick() {
    setShowModal(false);
  }

  async function handleClick() {
    const [profileDataResponse, isOrganisationAdminResponse] = await Promise.all([
      getProfileData(),
      isOrganisationAdmin(profileId),
    ]);

    if (
      profileDataResponse &&
      profileDataResponse.status === 200 &&
      isOrganisationAdminResponse &&
      isOrganisationAdminResponse.status === 200
    ) {
      const payload = {
        organization_name: isOrganisationAdminResponse.data.organization_name,
        email: profileDataResponse.data.email,
        first_name: profileDataResponse.data.firstname,
        last_name_prefix: profileDataResponse.data.lastname_prefix,
        last_name: profileDataResponse.data.lastname,
        phone_number: profileDataResponse.data.phone_number,
      };

      const extendSeatsResponse = await sendEnterpriseExtendSeatsRequest(payload);

      if (extendSeatsResponse && extendSeatsResponse.status === 204) {
        setEmailSent(true);
        setShowModal(false);
      } else {
        setEmailNotSent(true);
      }
    } else {
      setEmailNotSent(true);
    }
  }

  useEffect(() => {
    if (emailSent) {
      toast.success('Email verzonden.', {
        position: 'top-right',
        icon: <Icon iconName="check" color="text-green-500" />,
        autoClose: 3000,
        closeButton: false,
        bodyClassName: 'font-primary text-green-500',
      });
      setEmailSent(false);
    }

    if (emailNotSent) {
      toast.error('Email niet verzonden.', {
        position: 'top-right',
        autoClose: 3000,
        icon: <Icon iconName="exclamation" color="text-functional-error" />,
        closeButton: false,
        bodyClassName: 'font-primary text-functional-error',
      });
      setEmailNotSent(false);
    }
  }, [emailSent, emailNotSent]);

  return (
    <div className="shadow-xl">
      <div className="rounded-t-md bg-white px-4 py-3 sm:px-6 md:px-8">
        {/* Seat summary */}
        <div className="flex items-center justify-between">
          <div className="font-primary font-bold">Licentieplaatsen</div>
          <div className="flex flex-col">
            <span className="inline-flex gap-1 font-secondary text-sm tracking-wide">
              <p className="font-bold">{seatsConsumed}</p>
              <p>van</p>
              <p className="font-bold">{total}</p>
              <p>plaatsen gebruikt</p>
            </span>
          </div>
        </div>
        {/* Dynamic bar */}
        <div className="mt-2 h-2 rounded-full border border-gray-200 bg-gray-100 sm:mt-3 md:mb-2 md:mt-4 md:h-3">
          <ProgressBar fractionActive={fractionActive} fractionPending={fractionPending} />
        </div>
      </div>
      {/* Link to edit license seats */}
      <div className="flex justify-between rounded-b-md bg-accent-4 px-4 py-3 sm:px-6 md:px-8">
        <Button
          buttonType="notLink"
          onClick={onClickModal}
          label={fractionActive >= 100 ? 'Breid licentie uit' : 'Pas licentie aan'}
          overrideClass={'text-sm'}
        />
        <Modal
          showModal={showModal}
          setShowModal={setShowModal}
          modalContent={
            <>
              <h1 className="pb-4 text-xl font-bold md:pb-6 lg:pb-8">
                Uitbreiden Enterprise licentie
              </h1>
              <div className="flex flex-col justify-start pb-0 text-sm md:pb-4 lg:pb-8">
                <div className="flex gap-4 rounded-md bg-accent-2 px-4 py-2">
                  <span>
                    <h2 className="font-primary text-sm">
                      Wil je het aantal toegestane profielen op je licentie uitbreiden? Stuur dan
                      een verzoek naar de klantenservice. Er wordt contact met je opgenomen voor
                      verwerking van je verzoek.
                    </h2>
                    <div className="font-bold">{}</div>
                  </span>
                </div>
              </div>
              <div className="flex justify-end gap-4 pt-6">
                <Button label="Annuleren" type="button" buttonType="secondary" onClick={onClick} />
                <Button
                  label="Verstuur verzoek"
                  type="button"
                  buttonType="primary"
                  onClick={handleClick}
                />
              </div>
            </>
          }
        />
        <div className="flex items-center gap-5 text-sm">
          <LegendItem label={'Gebruikt'} bgColor={'bg-accent'} />
          <LegendItem label={'In behandeling'} bgColor={'bg-accent/60'} />
        </div>
      </div>
    </div>
  );
};

Seats.propTypes = {
  used: PropTypes.number.isRequired,
  pending: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
};

export default Seats;
